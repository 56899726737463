import { ILaunchDetails, ILaunchBaseInfo, ILaunch } from '@/interfaces/ILaunch';
import { IPeriodicVestingInfo, ILinearVestingInfo } from '@/interfaces/IVestingInfo';
import { defineStore } from 'pinia';
import BigNumber from 'bignumber.js';
import { useMainStore } from './main';
import { approveERC20, getAllowance, getTokenBalance, getTokenSymbol } from '../utils/contract-call-utils';
import * as details from '../assets/launch-details/details.json';

interface LaunchpadState {
  mainStore: any;
  launches: Record<number, ILaunch>;
  periodicVestings: IPeriodicVestingInfo[];
  linearVestings: Record<number, ILinearVestingInfo>;
  launchIds: number[];
  fundingPeriodPhase1: number;
  fundingPeriodPhase2: number;
  unclaimedToAllowanceMultiplier: number;
  unclaimedAllocationPercentage: number;
  commitTimeWindow: number;
  commitStartOffset: number;
}

export const useLaunchpadStore = defineStore('launchpad', {
  state: () => ({
    mainStore: useMainStore(),
    launches: {},
    launchIds: [],
    fundingPeriodPhase1: 0,
    fundingPeriodPhase2: 0,
    unclaimedToAllowanceMultiplier: 0,
    unclaimedAllocationPercentage: 0,
    commitTimeWindow: 0,
    commitStartOffset: 0,
    periodicVestings: [],
    linearVestings: {}
  }) as LaunchpadState,

  getters: {
    // getters receive the state as first parameter
    // doubleCount: (state) => state.counter * 2,
    // use getters in other getters
    // doubleCountPlusOne(): number {
    //   return this.doubleCount + 1
    // },
    getLaunch: (state) => {
      return (launchId: number) => state.launches[launchId] as ILaunch;
    },

    sortedLaunchIds: (state) => {
      return state.launchIds
        .sort((a,b) => { return state.launches[a].startTime - state.launches[b].startTime; });
    },

    upcomingLaunchIds(): number[] {
      return this.sortedLaunchIds.filter(launchId => !this.launches[launchId].startTime || this.launches[launchId].startTime > Date.now()/1000);
    },

    completedLaunchIds(): number[] {
      return this.sortedLaunchIds.filter(
        launchId => this.launches[launchId].phase % 2 === 1 &&
        this.launches[launchId].startTime &&
        this.launches[launchId].startTime + this.fundingPeriodPhase1 < Date.now()/1000
      );
    },

    featuredLaunchId(): number {
      let launchId = this.sortedLaunchIds.find(launchId => {
        const launch = this.launches[launchId];
        const launchPeriod = launch.phase === 1 ? this.fundingPeriodPhase1 : this.fundingPeriodPhase2;
        return launch.startTime < Date.now()/1000 && launch.startTime + launchPeriod > Date.now()/1000;
      });
      if(!launchId) {
        launchId = this.upcomingLaunchIds.length > 0 ? this.upcomingLaunchIds[this.upcomingLaunchIds.length - 1] : 0;
      }
      if(!launchId) {
        launchId = this.sortedLaunchIds.length > 0 ? this.sortedLaunchIds[this.sortedLaunchIds.length - 1] : 0;
      }

      return launchId;
    },

    sortedPeriodicVestings(): IPeriodicVestingInfo[] {
      return this.periodicVestings.sort((a, b) => { return b.vestingStartTimestamp - a.vestingStartTimestamp;}) as IPeriodicVestingInfo[];
    },

    sortedLinearVestingsIds(): number[] {
      return this.launchIds
        .filter(launchId => this.linearVestings[launchId]?.vestingDuration)
        .sort((a, b) => { return this.linearVestings[b].vestingStartTimestamp - this.linearVestings[a].vestingStartTimestamp; });
    }
  },

  actions: {
    async fetchVars() {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.defaultAccount) return;

      console.log('fetching vars');
      const dateNow = Date.now();
      [
        this.fundingPeriodPhase1,
        this.fundingPeriodPhase2,
        this.unclaimedToAllowanceMultiplier,
        this.unclaimedAllocationPercentage,
        this.commitTimeWindow,
        this.commitStartOffset
      ] = await Promise.all([
        +await Launchpad.methods.vars(2).call({from: this.mainStore.defaultAccount}),
        +await Launchpad.methods.vars(3).call({from: this.mainStore.defaultAccount}),
        +await Launchpad.methods.vars(4).call({from: this.mainStore.defaultAccount}),
        +await Launchpad.methods.vars(5).call({from: this.mainStore.defaultAccount}),
        +await Launchpad.methods.vars(6).call({from: this.mainStore.defaultAccount}),
        +await Launchpad.methods.vars(7).call({from: this.mainStore.defaultAccount})
      ]);
      console.log('fetched vars in', Date.now() - dateNow, 'ms');
    },

    async fetchAllLaunchDetails() {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.defaultAccount) return;

      console.log('fetching launch details');
      const dateNow = Date.now();

      this.launchIds = [];
      const launchesCount = +await Launchpad.methods.nextLaunchId().call({from: this.mainStore.defaultAccount});
      for(let i = launchesCount - 1; i > 0; i--) {
        const [launchBaseInfoRaw, launchDetailsRaw] = await Promise.all([
          Launchpad.methods.launches(i).call({from: this.mainStore.defaultAccount}),
          Launchpad.methods.getLaunchDetails(i).call({from: this.mainStore.defaultAccount})
        ]);
        const launchDetails = launchDetailsRaw as ILaunchDetails;
        const launchBaseInfo = launchBaseInfoRaw as ILaunchBaseInfo;

        // if launch has blank name (was removed)
        if(!launchBaseInfo.name) continue;

        // all phase 2 launches have even id
        if(i % 2 === 0 && +launchBaseInfo.phase !== 2) continue;

        let fundingTokenSymbol = '';
        try {
          fundingTokenSymbol = await getTokenSymbol(launchBaseInfo.fundingTokenAddress);
        }
        catch {
          console.error('Bad funding token address');
        }
        // SLOW IPFS, ASSETS STORED LOCALLY FOR NOW
        //const detailsJson = await (await fetch(launchBaseInfo.detailsJsonUri)).json();
        const detailsJson = (details as Record<string, any>)[launchBaseInfo.name.toLocaleLowerCase()];
        if(!detailsJson) continue;

        this.launches[i] = {
          id: i,
          name: launchBaseInfo.name,
          tokenSymbol: launchBaseInfo.tokenSymbol,
          tokenName: detailsJson.tokenName,
          totalSupply: +detailsJson.totalSupply,
          description: detailsJson.description,
          summary: detailsJson.summary,
          youtubeVideoUrl: detailsJson.youtubeVideoUrl,
          comparison: detailsJson.comparison,
          tokenomics: detailsJson.tokenomics,
          tokenomicsImageUrls: detailsJson.tokenomicsImageUrls,
          roadmap: detailsJson.roadmap,
          roadmapImageUrls: detailsJson.roadmapImageUrls,
          twitterUrl: detailsJson.twitterUrl,
          facebookUrl: detailsJson.facebookUrl,
          discordUrl: detailsJson.discordUrl,
          redditUrl: detailsJson.redditUrl,
          telegramUrl: detailsJson.telegramUrl,
          websiteUrl: detailsJson.websiteUrl,
          //imageUrl: launchBaseInfo.imageUrl,
          imageUrl: detailsJson.imageUrl,
          backgroundImageUrl: detailsJson.backgroundImageUrl,
          fundingTokenAddress: launchBaseInfo.fundingTokenAddress,
          fundingTokenSymbol,
          phase: +launchBaseInfo.phase,
          tokenPrice: new BigNumber(launchDetails.tokenPrice),
          startTime: +launchDetails.startTime,
          fundsToRaise: new BigNumber(launchDetails.fundsToRaise),
          maxAllocation: new BigNumber(launchDetails.maxAllocation),
          totalRaised: new BigNumber(launchDetails.totalRaised),
          tokenAddress: launchDetails.tokenAddress,
          commitOnly: launchBaseInfo.commitOnly,
          launchChain: detailsJson.launchChain,
          tokenDistributionTimestamp: detailsJson.tokenDistributionTimestamp
        };
        console.log(this.launches[i]);
        this.launchIds.push(i);
      }

      console.log('fetched launch details in', Date.now() - dateNow, 'ms');
    },

    async fetchLaunchProgress(launchId: number) {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.defaultAccount) return;

      this.launches[launchId].totalRaised = new BigNumber(await Launchpad.methods.launchTotalRaised(launchId).call({from: this.mainStore.defaultAccount}));
    },

    async getAllocation(launchId: number): Promise<BigNumber> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return new BigNumber(0);

      const remainingAllocation = await Launchpad.methods.getUserRemainingAllocationForLaunch(this.mainStore.defaultAccount, launchId)
        .call({from: this.mainStore.defaultAccount});
      return new BigNumber(remainingAllocation);
    },

    async hasEnoughAllowance(launchId: number): Promise<boolean> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected || this.launches[launchId].commitOnly) return false;

      const remainingAllocation = await Launchpad.methods.getUserRemainingAllocationForLaunch(this.mainStore.defaultAccount, launchId)
        .call({from: this.mainStore.defaultAccount});
      const allowance = await getAllowance(this.launches[launchId].fundingTokenAddress);

      return allowance.gte(remainingAllocation);
    },

    async approveERC20Spend(launchId: number, amount: string) {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return;

      await approveERC20(this.launches[launchId].fundingTokenAddress, amount);
    },

    async invest(launchId: number, amount: string) {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return;

      await Launchpad.methods.invest(launchId, amount).send({from: this.mainStore.defaultAccount});
    },

    async commitUnclaimedSkill(launchId: number, amount: string, targetWalletAddress: string = '') {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return;
      if(!targetWalletAddress) targetWalletAddress = this.mainStore.defaultAccount;

      await Launchpad.methods.commitUnclaimedSkill(launchId, amount, targetWalletAddress).send({from: this.mainStore.defaultAccount});
    },

    async fetchTotalUnclaimedCommittedValue(launchId: number): Promise<BigNumber> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.defaultAccount) return new BigNumber(0);

      return new BigNumber(await Launchpad.methods.getUnclaimedCommittedValue(launchId).call({from: this.mainStore.defaultAccount}));
    },

    async fetchUserUnclaimedCommittedValue(launchId: number): Promise<BigNumber> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return new BigNumber(0);

      return new BigNumber(
        await Launchpad.methods.launchUserUnclaimedSkillCommittedValue(launchId, this.mainStore.defaultAccount)
          .call({from: this.mainStore.defaultAccount})
      );
    },

    async fetchIsCurrentUserWhitelisted(launchId: number): Promise<boolean> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return false;

      return await Launchpad.methods.isUserWhitelisted(this.mainStore.defaultAccount, launchId).call({from: this.mainStore.defaultAccount});
    },

    async fetchIsUserWhitelisted(address: string, launchId: number): Promise<boolean> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.defaultAccount) return false;

      return await Launchpad.methods.isUserWhitelisted(address, launchId).call({from: this.mainStore.defaultAccount});
    },

    async fetchFundingTokenBalance(launchId: number): Promise<BigNumber> {
      if(!this.mainStore.isWalletConnected || this.launches[launchId].commitOnly) return new BigNumber(0);
      return new BigNumber(await getTokenBalance(this.getLaunch(launchId).fundingTokenAddress));
    },

    async fetchSkillPrice(): Promise<BigNumber> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.defaultAccount) return new BigNumber(0);

      return new BigNumber(await Launchpad.methods.skillPrice().call({from: this.mainStore.defaultAccount}));
    },

    async fetchVestings() {
      const dateNow = Date.now();
      await Promise.all([
        this.fetchPeriodicVestings(),
        this.fetchLinearVestings()
      ]);

      console.log('fetched vestings in', Date.now() - dateNow, 'ms');
    },

    async fetchPeriodicVestings() {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.defaultAccount) return;

      this.periodicVestings = [];
      for(const launchId of this.launchIds) {
        // claiming is done on phase 1s
        if(launchId % 2 === 0) continue;
        const launchVestingPercentages = await Launchpad.methods.getLaunchVestingPercentages(launchId).call({from: this.mainStore.defaultAccount});
        for(let i = 0; i < launchVestingPercentages.length; i++) {
          const [userTotalInvestment, didUserClaimVesting, vestingStartTimestamp] = await Promise.all([
            this.fetchTotalUserInvestment(launchId),
            this.fetchDidUserClaimVesting(launchId, i),
            Launchpad.methods.launchPeriodicVestingsStartTimestamps(launchId, i).call({from: this.mainStore.defaultAccount})
          ]);
          this.periodicVestings.push({
            launchId: +launchId,
            vestingId: i,
            vestingPercentage: +launchVestingPercentages[i],
            vestingStartTimestamp,
            userTotalInvestment: new BigNumber(userTotalInvestment),
            didUserClaimVesting
          });
        }
      }
    },

    async fetchLinearVestings() {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.defaultAccount) return;

      this.linearVestings = [];
      for(const launchId of this.launchIds) {
        // claiming is done on phase 1s
        if(launchId % 2 === 0) continue;
        const [vestingDuration, vestingStartTimestamp] = await Promise.all([
          Launchpad.methods.launchLinearVestingsDurations(launchId).call({from: this.mainStore.defaultAccount}),
          Launchpad.methods.launchLinearVestingsStartTimestamps(launchId).call({from: this.mainStore.defaultAccount})
        ]);
        if(vestingDuration === 0) continue;

        this.linearVestings[launchId] = { vestingDuration: +vestingDuration, vestingStartTimestamp: +vestingStartTimestamp };
      }
    },

    async fetchTotalUserInvestment(launchId: number): Promise<BigNumber> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return new BigNumber(0);

      return new BigNumber(await Launchpad.methods.getTotalLaunchUserInvestment(launchId).call({from: this.mainStore.defaultAccount}));
    },

    async fetchLaunchUserInvestment(launchId: number): Promise<BigNumber> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return new BigNumber(0);

      return new BigNumber(await Launchpad.methods.launchUserInvestment(launchId, this.mainStore.defaultAccount).call({from: this.mainStore.defaultAccount}));
    },

    async fetchDidUserClaimVesting(launchId: number, vestingId: number): Promise<boolean> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return false;

      return await Launchpad.methods.userClaimedVestingPortion(this.mainStore.defaultAccount, launchId, vestingId).call({from: this.mainStore.defaultAccount});
    },

    async claimTokensPeriodic(launchId: number, vestingId: number) {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return;

      await Launchpad.methods.claimPeriodic(launchId, vestingId).send({from: this.mainStore.defaultAccount});
      const vestingIndex = this.periodicVestings.findIndex(v => v.launchId === +launchId && v.vestingId === +vestingId);
      this.periodicVestings[vestingIndex].didUserClaimVesting = await this.fetchDidUserClaimVesting(launchId, vestingId);
    },

    async claimTokensLinear(launchId: number) {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return;

      await Launchpad.methods.claimLinear(launchId).send({from: this.mainStore.defaultAccount});
    },

    async fetchLinearClaimAmount(launchId: number): Promise<BigNumber> {
      const { Launchpad } = this.mainStore.contracts();
      if(!Launchpad || !this.mainStore.isWalletConnected) return new BigNumber(0);

      return new BigNumber(await Launchpad.methods.getLinearClaimAmount(launchId).call({from: this.mainStore.defaultAccount}));
    }
  }
});